import React, { useMemo } from 'react';
import { Category, CategoryDescription } from 'shared/types/product/Category';
import { Product } from 'shared/types/product/Product';
import Container from 'components/commercetools-ui/atoms/container';
import { BannerProps } from 'components/commercetools-ui/organisms/product/product-list/types';
import Wrapper from 'components/HOC/wrapper';
import Breadcrumbs from './components/breadcrumb';
import CurrentRefinements from './components/current-refinements';
import DesktopFacets from './components/desktop-facets';
import List from './components/list';
import MobileFacets from './components/mobile-facets';
import Pagination from './components/pagination';
import SearchHeader from './components/search-header';
import Seo from './components/seo';
import { useProductList } from './context';

export interface Props {
  categories: Category[];
  products: Product[];
  banners: BannerProps[];
  categoryDescription: CategoryDescription | undefined;
}

const ProductList: React.FC<Props> = ({ products, categories, banners, categoryDescription }) => {
  const { slug, searchQuery, firstPage, currentPage } = useProductList();

  const category = useMemo(() => categories.find((category) => category.slug === slug), [categories, slug]);

  return (
    <div className="min-h-screen bg-background pb-32 lg:pb-40">
      <Container>
        {searchQuery ? (
          <Wrapper background="background" className="pt-32 lg:pt-40">
            <SearchHeader query={searchQuery ?? ''} />
          </Wrapper>
        ) : (
          <Breadcrumbs categories={categories} categoryId={category?.categoryId} banners={banners} />
        )}

        <Wrapper className="relative" background="background">
          <MobileFacets />
          <DesktopFacets page="Search" />
          <CurrentRefinements />
        </Wrapper>

        <List products={products} />

        <Pagination />

        {currentPage === firstPage && categoryDescription ? <Seo content={categoryDescription}></Seo> : null}
      </Container>
    </div>
  );
};

export default ProductList;
